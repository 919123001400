/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';
import 'bootstrap/dist/js/bootstrap.js';
import 'dropzone/dist/basic.css';
import 'dropzone/dist/dropzone.css';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import '@fortawesome/fontawesome-free/css/brands.css';

// Import TinyMCE
import tinymce from 'tinymce/tinymce';

// A theme is also required
import 'tinymce/themes/mobile/theme';
import 'tinymce/icons/default';

// Any plugins you want to use has to be imported
import 'tinymce/plugins/paste';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/wordcount';
import 'tinymce-i18n/langs5/nl';

document.addEventListener('DOMContentLoaded', () => {
    // Initialize the app
    tinymce.init({
        language: 'nl',
        selector: '.tinymce',
        menubar: false,
        plugins: ['paste', 'lists', 'wordcount'],
        branding: false,
        statusbar: true,
        height : '480',
        toolbar: 'undo redo | ' +
            'bold italic underline | bullist numlist outdent indent',
    });
});


import * as dropzone from 'dropzone/dist/min/dropzone.min.js';
dropzone.prototype.defaultOptions.dictDefaultMessage = "Sleep foto’s of afbeeldingen hiernaartoe om te uploaden.";
dropzone.prototype.defaultOptions.dictFallbackMessage = "Uw browser ondersteunt het uploaden van bestanden via drag and drop niet.";
dropzone.prototype.defaultOptions.dictFallbackText = "Gebruik het onderstaande formulier om je bestanden te uploaden.";
dropzone.prototype.defaultOptions.dictFileTooBig = "Het bestand is te groot ({{filesize}}MiB). Maximum grootte: {{maxFilesize}}MiB.";
dropzone.prototype.defaultOptions.dictInvalidFileType = "U kunt geen bestanden van dit type uploaden.";
dropzone.prototype.defaultOptions.dictResponseError = "Server antwoordde met {{statusCode}} code.";
dropzone.prototype.defaultOptions.dictCancelUpload = "Uploaden annuleren";
dropzone.prototype.defaultOptions.dictCancelUploadConfirmation = "Weet u zeker dat u deze upload wilt annuleren?";
dropzone.prototype.defaultOptions.dictRemoveFile = "Bestand verwijderen";
dropzone.prototype.defaultOptions.dictMaxFilesExceeded = "U kunt geen nieuwe bestanden meer uploaden.";

dropzone.options.bookItemDropzone = {
    maxThumbnailFilesize: 30,
    acceptedFiles: "image/*",
    maxFiles: 5,
    addRemoveLinks: true,
    accept: function(file, done) {
        file.acceptDimensions = done;
        file.rejectDimensions = function(msg) { done(msg); };
    },
    init: function() {
        this.on("success", function(file, responseObj) {
            file.deleteUrl = responseObj.deleteUrl;
        });

        this.on("thumbnail", function (file) {
            file.acceptDimensions();
        });

        this.on("removedfile", function(file) {
            let xhr = new XMLHttpRequest();
            xhr.open("DELETE", file.deleteUrl);
            xhr.send(null);
        });

        let myDropzone = this;
        let emptyFn = () => {};
        let currentImages = JSON.parse(myDropzone.element.getAttribute("data-current-images") || "[]");

        currentImages.forEach((el) => {
            let mockFile = {
                id: el.id,
                name: el.name,
                size: el.size,
                deleteUrl: el.deleteUrl,
                rejectDimensions: emptyFn,
                acceptDimensions: emptyFn,
            };

            myDropzone.displayExistingFile(mockFile, el.url, undefined, 'anonymous');
        });

        // If you use the maxFiles option, make sure you adjust it to the
        // correct amount:
        let fileCountOnServer = currentImages.length;
        myDropzone.options.maxFiles = myDropzone.options.maxFiles - fileCountOnServer;
    }
}
